import React from 'react';
import Card from 'components/library/Card/Card';
import './CampaignSummary.css';
import useInView from 'infrastructure/useInView';
import { subscribe } from 'reffects-store';
import {
  campaignObjectiveTypeLabelSelector,
  currencySymbolSelector,
  formDataSelector,
  isDuplicatedCampaignSelector,
  showCampaignObjectiveTypeSelector,
} from 'components/UnifiedCampaignForm/selectors';
import {
  formatedCPCSelector,
  formattedBudgetSelector,
  matchingAdsSelector,
} from 'components/UnifiedCampaignForm/TargetingForm/selectors';

function CampaignSummary({
  formData,
  currencySymbol,
  matchingAds,
  shouldShowNumAds,
  cpc,
  budgetAmountFormatted,
  isNotADuplicatesCampaign,
  campaignObjectiveType,
  showCampaignObjectiveType,
}) {
  const { startDateAsString } = formData;
  const [ref] = useInView({ threshold: 1 });

  return (
    <div className="CampaignSummary__wrapper">
      <div ref={ref}>
        <Card>
          <h2 className="CampaignSummary__title">Summary</h2>
          {showCampaignObjectiveType && (
            <div className="CampaignSummary__field">
              <span className="CampaignSummary__fieldName">
                Campaign objective
              </span>
              <span className="CampaignSummary__fieldValue">
                {campaignObjectiveType}
              </span>
            </div>
          )}
          {shouldShowNumAds && (
            <>
              <div className="CampaignSummary__field">
                <span className="CampaignSummary__fieldName">
                  Trovit ads promoted
                </span>
                <span className="CampaignSummary__fieldValue">
                  {matchingAds.numTrovitAds}
                </span>
              </div>
              {isNotADuplicatesCampaign && (
                <>
                  <div className="CampaignSummary__field">
                    <span className="CampaignSummary__fieldName">
                      Mitula ads promoted
                    </span>
                    <span className="CampaignSummary__fieldValue">
                      {matchingAds.numMitulaAds}
                    </span>
                  </div>
                  <div className="CampaignSummary__field">
                    <span className="CampaignSummary__fieldName">
                      Nestoria ads promoted
                    </span>
                    <span className="CampaignSummary__fieldValue">
                      {matchingAds.numNestoriaAds}
                    </span>
                  </div>
                  <div className="CampaignSummary__field">
                    <span className="CampaignSummary__fieldName">
                      Nuroa ads promoted
                    </span>
                    <span className="CampaignSummary__fieldValue">
                      {matchingAds.numNuroaAds}
                    </span>
                  </div>
                </>
              )}
            </>
          )}
          <div className="CampaignSummary__field">
            <span className="CampaignSummary__fieldName">Budget</span>
            <span className="CampaignSummary__fieldValue">
              {budgetAmountFormatted} {currencySymbol}
            </span>
          </div>
          <div className="CampaignSummary__field">
            <span className="CampaignSummary__fieldName">CPC</span>
            <span className="CampaignSummary__fieldValue">
              {cpc} {currencySymbol}
            </span>
          </div>
          <div>
            <span className="CampaignSummary__startDate">
              Your campaign starts on {startDateAsString}
            </span>
          </div>
        </Card>
      </div>
    </div>
  );
}

export default subscribe(
  CampaignSummary,
  (state) => ({
    formData: formDataSelector(state),
    currencySymbol: currencySymbolSelector(state),
    matchingAds: matchingAdsSelector(state, trovitData.localeISO3166),
    cpc: formatedCPCSelector(state, trovitData.localeISO3166),
    budgetAmountFormatted: formattedBudgetSelector(
      state,
      trovitData.localeISO3166
    ),
    isNotADuplicatesCampaign: !isDuplicatedCampaignSelector(state),
    campaignObjectiveType: campaignObjectiveTypeLabelSelector(state),
    showCampaignObjectiveType: showCampaignObjectiveTypeSelector(state),
  }),
  {
    shouldShowNumAds: trovitData.shouldShowNumAds,
  }
);

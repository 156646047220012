import React, { useEffect, useState } from 'react';
import { dispatch } from 'reffects';
import { subscribe } from 'reffects-store';
import Card from 'components/library/Card/Card';
import Header1 from 'components/library/Header1/Header1';
import Header5 from 'components/library/Header5/Header5';
import Note from 'components/library/Note/Note';
import Radio from 'components/library/Input/Radio/Radio';
import GeoCriterionForm from './Geolocation/GeoCriterionForm';
import GeoHelpTooltip from './Geolocation/GeoHelpTooltip';
import GeoCriteriaToggle from './Geolocation/GeoCriteriaToggle';
import {
  audienceBehaviorCriteriaSelector,
  audienceGeoCriteriaSelector,
  availableGeoCriteriaSelector,
} from './selectors';
import './AudienceSegmentationForm.css';
import '../TargetingForm/TargetingForm.css';

function Box(props) {
  const { children } = props;
  return <div className="Box">{children}</div>;
}

function GeoTargetingCard(props) {
  const { selectedGeoCriteria = [], onRemove } = props;
  const [isGeoToggleOn, setIsGeoToggleOn] = useState(false);

  useEffect(
    () => setIsGeoToggleOn(selectedGeoCriteria.length > 0),
    [selectedGeoCriteria]
  );
  return (
    <Card>
      <div className="CampaignForm__cardWrapper CampaignForm__cardWrapper--threeColumns">
        <div className="CampaignForm__cardWrapperTitle">
          <Header5>
            {trovitApp.i18n('user_criteria_geo_main')}
            <GeoHelpTooltip />
          </Header5>
        </div>
        <div className="CampaignForm__cardWrapperInput">
          <GeoCriteriaToggle
            isOn={isGeoToggleOn}
            toggleOn={(isOn) => {
              selectedGeoCriteria.forEach(({ field }) => onRemove(field));
              setIsGeoToggleOn(isOn);
            }}
          />
          <div className="targeting">
            <GeoCriterionForm
              isOn={isGeoToggleOn}
              key="geo-form-user_country"
              criterion={{
                field: 'user_country',
                label: trovitApp.i18n('criteria_country_title'),
              }}
            />
          </div>
        </div>
        <div className="CampaignForm__cardWrapper__note">
          <Note>
            <p>{trovitApp.i18n('audiences_explanation')}</p>
            <p>
              <a
                href="https://help.thribee.com/hc/en-us/articles/115003682394-Geotargeting"
                target="_blank"
                rel="noopener noreferrer"
              >
                {trovitApp.i18n('learn_more_help_center')}
              </a>
            </p>
            <Box>
              <strong>
                {trovitApp.i18n('create_campaign_bidding_cpa_warning_title')}
              </strong>
              <p>{trovitApp.i18n('geotargeting_english_tip')}</p>
            </Box>
          </Note>
        </div>
      </div>
    </Card>
  );
}

function BehaviorCriteriaToggle(props) {
  const { isOn, toggleOn, onChange } = props;
  return (
    <>
      <Radio
        id="radio-behavior-all-audience"
        value="radio-behavior-specific-audience"
        name="radio-behavior-specific-audience"
        checked={!isOn}
        onChange={() => toggleOn(false)}
      >
        {trovitApp.i18n('audience_all_kinds_of_users')}
      </Radio>
      <Radio
        id="radio-behavior-specific-content-only-new-users"
        name="user_new"
        checked={isOn}
        onChange={(event) => onChange(event.target.name)}
      >
        {trovitApp.i18n('audience_specific_behaviour_only_new_users')}
      </Radio>
    </>
  );
}

function BehaviorTargetingCard(props) {
  const { selectedCriteria, onChange, onClear } = props;
  const [isBehaviorToggleOn, setIsBehaviorToggleOn] = useState(false);
  useEffect(
    () => setIsBehaviorToggleOn(selectedCriteria !== ''),
    [selectedCriteria]
  );
  return (
    <Card>
      <div className="CampaignForm__cardWrapper CampaignForm__cardWrapper--threeColumns">
        <div className="CampaignForm__cardWrapperTitle">
          <Header5>{trovitApp.i18n('audience_behaviour')}</Header5>
        </div>
        <div className="CampaignForm__cardWrapperInput">
          <BehaviorCriteriaToggle
            isOn={isBehaviorToggleOn}
            toggleOn={(isOn) => {
              onClear();
              setIsBehaviorToggleOn(isOn);
            }}
            onChange={onChange}
          />
        </div>
        <div className="CampaignForm__cardWrapper__note">
          <Note />
        </div>
      </div>
    </Card>
  );
}

function AudienceSegmentationForm(props) {
  const {
    availableGeoCriteria,
    selectedAudienceGeoCriteria = [],
    selectedAudienceBehaviorCriteria = '',
    onSelectGeoCriteria,
    onRemove,
    onChangeBehaviorCriteria,
    onClearBehaviorCriteria,
  } = props;
  return (
    <>
      <Header1>
        {trovitApp.i18n('create_campaign_segmentation_audience_label')}
      </Header1>
      <GeoTargetingCard
        availableGeoCriteria={availableGeoCriteria}
        selectedGeoCriteria={selectedAudienceGeoCriteria}
        onSelectGeoCriteria={onSelectGeoCriteria}
        onRemove={onRemove}
      />
      <BehaviorTargetingCard
        selectedCriteria={selectedAudienceBehaviorCriteria}
        onChange={onChangeBehaviorCriteria}
        onClear={onClearBehaviorCriteria}
      />
    </>
  );
}

export default subscribe(
  AudienceSegmentationForm,
  function mapStateToProps(state) {
    return {
      selectedAudienceGeoCriteria: audienceGeoCriteriaSelector(state),
      availableGeoCriteria: availableGeoCriteriaSelector(state),
      selectedAudienceBehaviorCriteria: audienceBehaviorCriteriaSelector(state),
    };
  },
  {
    onRemove: (field) => {
      dispatch({ id: 'REMOVE_AUDIENCE_GEO_CRITERIA', payload: { field } });
    },
    onSelectGeoCriteria: (field) => {
      dispatch({ id: 'ADD_AUDIENCE_GEO_CRITERIA', payload: { field } });
    },
    onChangeBehaviorCriteria: (field) => {
      dispatch({ id: 'CHANGE_AUDIENCE_BEHAVIOR_CRITERIA', payload: { field } });
    },
    onClearBehaviorCriteria: () => {
      dispatch('CLEAR_AUDIENCE_BEHAVIOR_CRITERIA');
    },
  }
);
